const inViewport = (elem, threshold) => {
  if (!elem) {
    return false
  }
  const bounding = elem.getBoundingClientRect()
  return (
    (bounding.top - threshold || 0) <= (window.innerHeight || document.documentElement.clientHeight) &&
    (bounding.bottom + threshold || 0) >= 0
  )
}

export default inViewport
